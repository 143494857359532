import React from "react"
import { graphql, useStaticQuery } from "gatsby"


import LayoutSlider from "../components/Layout/layout_slider"

const IndexPage = () => {
  const data = useStaticQuery(query)
  data.strapiHomepage.content = data.strapiHomepage.content.split(`img src="/upload`).join(`img src="${process.env.GATSBY_API_URL}/upload`);
  return (
    <LayoutSlider seo={data.strapiHomepage.seo}>
      <div>
        <h1 className='title-page'>{data.strapiHomepage.title}</h1>
        <div className="uk-section">
          <div className="uk-container uk-container">
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: data.strapiHomepage.content }} />
          </div>
        </div>
      </div>
    </LayoutSlider>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      content
      title
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default IndexPage