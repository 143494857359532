import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Nav from "../Navbar/nav"
import Seo from "../Seo/seo"
import Footer from "../Footer/footer"
import SideBar from "../SideBar/sidebar"
import Img from "gatsby-image"
import Cookies from "../Cookies/cookies"

const LayoutSlider = ({ children, seo }) => {
  const data = useStaticQuery(query)

  return (
    <>
      <Seo seo={seo} />
      <Nav />
      <div className="uk-position-relative uk-visible-toggle uk-light"
           data-uk-slideshow="autoplay: true; max-height: 700; animation: fade">
        <ul className="uk-slideshow-items">
          {data.strapiHomepage.images.map((image, i) => {
            return (
              <div
                className="uk-position-cover uk-animation-kenburns">
                <li>
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    imgStyle={{ position: "static" }}
                  />
                </li>
              </div>
            )
          })}
        </ul>

        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="/" data-uk-slidenav-previous
           data-uk-slideshow-item="previous"> </a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="/" data-uk-slidenav-next
           data-uk-slideshow-item="next"> </a>

      </div>
      <main>
        <div className="uk-container">
          {children}
        </div>
      </main>
      <Cookies />
      <Footer />
      <SideBar />
    </>
  )
}
LayoutSlider.propTypes =
  {
    children: PropTypes.node.isRequired
  }
const query = graphql`
    query
    {
        strapiHomepage
        {
            images
            {
                localFile
                {
                    childImageSharp {
                        fluid(maxHeight: 2000) {
                            src
                        }
                    }
                }
            }
            seo
            {
                metaTitle
                metaDescription
            }
        }
    }

`

export default LayoutSlider